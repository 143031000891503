import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Tooltip from "@material-ui/core/Tooltip";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import MuiAvatar from "@material-ui/core/Avatar";

import logoAws from "../images/code-logos/logo-aws.svg";
import logoCodeMirror from "../images/code-logos/logo-codemirror.svg";
import logoCss from "../images/code-logos/logo-css.svg";
import logoFlux from "../images/code-logos/logo-flux.svg";
import logoGatsby from "../images/code-logos/logo-gatsby.svg";
import logoGit from "../images/code-logos/logo-git.svg";
import logoGoogleCloud from "../images/code-logos/logo-google-cloud.svg";
import logoHtml from "../images/code-logos/logo-html.svg";
import logoJavascript from "../images/code-logos/logo-javascript.svg";
import logoMaterialUi from "../images/code-logos/logo-material-ui.svg";
import logoNetlify from "../images/code-logos/logo-netlify.svg";
import logoNextJs from "../images/code-logos/logo-next-js.svg";
import logoNodeJs from "../images/code-logos/logo-nodejs.svg";
import logoPython from "../images/code-logos/logo-python.svg";
import logoReact from "../images/code-logos/logo-react.svg";
import logoTensorFlow from "../images/code-logos/logo-tensorflow.svg";
import logoTypescript from "../images/code-logos/logo-typescript.svg";
import logoWebpack from "../images/code-logos/logo-webpack.svg";
import logoWebStorm from "../images/code-logos/logo-webstorm.svg";
import { ButtonBase } from "@material-ui/core";

export const logos = {
  aws: { image: logoAws, title: "AWS" },
  codemirror: { image: logoCodeMirror, title: "CodeMirror" },
  css: { image: logoCss, title: "CSS" },
  flux: { image: logoFlux, title: "Flux" },
  "gatsby-js": { image: logoGatsby, title: "Gatsby JS" },
  git: { image: logoGit, title: "Git" },
  "google-cloud": { image: logoGoogleCloud, title: "Google Cloud" },
  html: { image: logoHtml, title: "HTML" },
  javascript: { image: logoJavascript, title: "JavaScript" },
  "material-ui": { image: logoMaterialUi, title: "Material UI" },
  netlify: { image: logoNetlify, title: "Netlify" },
  "node-js": { image: logoNodeJs, title: "Node JS" },
  "next-js": { image: logoNextJs, title: "Next.js" },
  python: { image: logoPython, title: "Python" },
  "react-js": { image: logoReact, title: "React JS" },
  "styled-components": { text: "💅", title: "Styled Components" },
  tensorflow: { image: logoTensorFlow, title: "TensorFlow" },
  tool: { text: "🛠️", title: "Tool" },
  typescript: { image: logoTypescript, title: "TypeScript" },
  webpack: { image: logoWebpack, title: "Webpack" },
  webstorm: { image: logoWebStorm, title: "WebStorm" },
  /*  novice: { icon: <NoviceIcon/>, title: "Novice"},
    beginner: { icon: <BeginnerIcon/>, title: "Beginner"},
    intermediate: { icon: <IntermediateIcon/>, title: "Intermediate"},
    advanced: { icon: <AdvancedIcon/>, title: "Advanced"},
    expert: { icon: <ExpertIcon/>, title: "Expert"},*/
};

const Avatar = styled(MuiAvatar).attrs((p) => ({ size: p.styledSize }))`
  width: ${(p) => (p.size ? `calc(${p.size * 0.618}vw)` : "4rem")};
  height: ${(p) => (p.size ? `calc(${p.size * 0.618}vw)` : "4rem")};
  margin: 0.1rem;
  font-size: ${(p) => (p.size ? `calc(${p.size * 0.618 ** 2}vw)` : "1rem")};
  @media all and (max-width: 680px) {
    width: ${(p) => (p.size ? `calc(${p.size}vw)` : "4rem")};
    height: ${(p) => (p.size ? `calc(${p.size}vw)` : "4rem")};
    font-size: ${(p) => (p.size ? `calc(${p.size * 0.618}vw)` : "1rem")};
  }
  img {
    object-fit: contain;
  }
`;

const GatsbyLink = styled(Link)`
  text-decoration: inherit;
  color: inherit;
`;

const LinkOrRefine = ({ refine, title, children }) =>
  typeof refine === "function" ? (
    <ButtonBase onClick={() => refine(title)}>{children}</ButtonBase>
  ) : (
    <GatsbyLink to={`/posts/?query=${encodeURIComponent(title)}`}>
      {children}
    </GatsbyLink>
  );

function CodeLogo({ tag, size, refine }) {
  const { title = "", image, text = null } = logos[tag];
  return (
    <LinkOrRefine refine={refine} title={title}>
      <Tooltip title={title}>
        <Avatar styledSize={size} variant="rounded" alt={title} src={image}>
          {text}
        </Avatar>
      </Tooltip>
    </LinkOrRefine>
  );
}

function CodeLogos({
  tags,
  size,
  justify = "start",
  flexFlow = "row nowrap",
  refine,
}) {
  if (tags === undefined || tags === null) return null;
  const validTags = tags.filter((tag) => logos[tag]);
  return (
    <AvatarGroup style={{ flexFlow, justifyContent: justify }}>
      {validTags.map((tag) => (
        <CodeLogo size={size} key={tag} tag={tag} refine={refine} />
      ))}
    </AvatarGroup>
  );
}

export default CodeLogos;
